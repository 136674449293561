<template>
  <div id="documents-list">
    <v-dialog v-model="dialog" eager max-width="940" :persistent="dialogPersistent">
      <v-card>
        <v-card-title v-if="dialogPersistent">
          <div class="pb-2 d-flex align-center justify-space-between" style="width: 100%">
            <div>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <v-icon large class="mr-2">
                  {{ icons.mdiDownload }}
                </v-icon>
                Belgeyi İndir
              </div>
            </div>
            <div>
              <span class="text-body-2 font-weight-bold mr-3">Doküman Numarası : </span>
              <v-chip label color="secondary">
                <span class="text-overline">{{ dialogNum }}</span>
              </v-chip>
            </div>
          </div>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-title>
        <v-card-title v-else>
          <div v-if="$vuetify.breakpoint.smAndUp">
            <v-icon large class="mr-2">
              {{ icons.mdiDownload }}
            </v-icon>
            Belgeyi İndir
          </div>

          <v-spacer />
          <v-btn color="error" depressed @click="dialog = false">
            Kapat
            <v-icon right>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div id="download"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetail" eager max-width="1400" :persistent="dialogPersistent">
      <v-card>
        <v-card-title v-if="dialogPersistent">
          <div class="pb-2 d-flex align-center justify-space-between" style="width: 100%">
            <div>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <v-icon large class="mr-2">
                  {{ icons.mdiEye }}
                </v-icon>
                Ayrıntılar
              </div>
            </div>
            <div>
              <span class="text-body-2 font-weight-bold mr-3">Doküman Numarası : </span>
              <v-chip label color="secondary">
                <span class="text-overline">{{ dialogNum }}</span>
              </v-chip>
            </div>
          </div>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-title>
        <v-card-title v-else>
          <div v-if="$vuetify.breakpoint.smAndUp">
            <v-icon large class="mr-2">
              {{ icons.mdiEye }}
            </v-icon>
            Ayrıntılar
          </div>

          <v-spacer />
          <v-btn color="error" depressed @click="dialogDetail = false">
            Kapat
            <v-icon right>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="!dialogPersistent" class="pa-0">
          <v-data-table :headers="detailHeaders" :items="detailItems" :items-per-page="-1" hide-default-footer>
            <template #item.QUANTITY="{ item }">
              <span> {{ item.QUANTITY }} {{ item.QUNIT }} </span>
            </template>
            <template #item.AVAILQTY="{ item }">
              <span> {{ item.AVAILQTY }} {{ item.QUNIT }} </span>            </template>
            
            <template #item.SPRICE="{ item }">
              <span> {{ item.SPRICE | currency }} </span>
            </template>
            <template #item.SUBTOTAL="{ item }">
              <span> {{ item.SUBTOTAL | currency }} </span>
            </template>
            <template #item.VATAMNT="{ item }">
              <span> {{ item.VATAMNT | currency }} </span>
            </template>
            <template #item.GRANDTOTAL="{ item }">
              <span> {{ item.GRANDTOTAL | currency }} </span>
            </template>
            <template #item.CURRENCY="{ item }">
              <span> {{ item.CURRENCY | currency }} </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-if="!status"
      :value="isFilterUserSidebarActive"
      temporary
      touchless
      floating
      stateless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
      :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    >
      <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="48" color="secondary">
            <v-icon size="24" class="white--text">
              {{ icons.mdiFilter }}
            </v-icon>
          </v-avatar>
          <div class="text-h6 font-weight-medium ml-3">Filtreleme</div>
        </div>
        <v-btn icon x-small @click="isFilterUserSidebarActive = false">
          <v-icon> {{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-sheet>
      <div @keyup.enter="fetchDocuments()">
        <v-menu
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.startDate"
              label="Başlangıç Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.startDate" no-title @input="startMenu = false"></v-date-picker>
        </v-menu>
        <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.endDate"
              label="Bitiş Tarihi"
              hide-details=""
              readonly
              filled
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.endDate" no-title @input="endMenu = false"></v-date-picker>
        </v-menu>

        <v-select
          v-if="docTypes !== 1"
          v-model="filter.docStatuses"
          label="Durum"
          class="rounded-0"
          filled
          hide-details
          :menu-props="{ bottom: true, offsetY: true }"
          :items="docStatusesItems"
        ></v-select>
        <v-text-field v-model="filter.docCustomer" class="rounded-0" filled hide-details label="Müşteri"></v-text-field>
        <v-text-field v-model="filter.docMaterial" class="rounded-0" filled hide-details label="Malzeme"></v-text-field>
      </div>

      <template v-slot:append>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-btn x-large block rounded class="text-capitalize" color="secondary" @click="fetchDocuments()">
                Filitrele
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <!-- <div class="pa-3">
          <v-btn x-large rounded block class="text-capitalize" color="secondary" @click="fetchDocuments()">
            Tamam
          </v-btn>
        </div> -->
      </template>
    </v-navigation-drawer>

    <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />

    <v-alert v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer" tile type="warning">
      Lütfen yukarıdan bir müşteri seçin
    </v-alert>

    <div v-else>
      <v-row dense>
        <v-col>
          <v-expand-transition>
            <v-card
              v-if="status"
              :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
              rounded="xl"
              class="mx-auto mt-md-9"
              :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
              :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
            >
              <v-img
                class="white--text align-end"
                :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                src="@/assets/images/pages/header-01.jpg"
                :height="$vuetify.breakpoint.smAndDown ? 260 : 260"
                gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
              >
                <div class="card-img--text card-img--text-type-1">
                  <div class="text-h3 font-weight-medium mb-0">
                    {{ title }}
                  </div>

                  <p class="text-body-2 white--text font-weight-thin mb-1" v-text="msg" />
                  <v-progress-linear v-show="loading" color="white" indeterminate></v-progress-linear>
                </div>
              </v-img>
              <v-expand-transition>
                <div v-show="!loading">
                  <v-card-text class="pa-0 pt-6">
                    <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-6' : null">
                      <v-col cols="12" md="6" class="pl-md-9 pr-md-2 px-9">
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted1"
                              label="Bitiş Tarihi1"
                              persistent-hint
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>

                          <v-date-picker
                            v-model="date1"
                            no-title
                            color="primary"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" class="pl-md-2 pr-md-9 px-9">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted"
                              label="Bitiş Tarihi"
                              persistent-hint
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>

                          <v-date-picker v-model="date" no-title color="primary" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-if="docTypes !== 1" cols="12" :md="docTypes !== 1 ? 6 : 12" class="pl-md-9 pr-md-2 px-9">
                        <v-select
                          v-model="filter.docStatuses"
                          label="Durum"
                          hide-details=""
                          :items="docStatusesItems"
                        ></v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        :md="docTypes !== 1 ? 6 : 12"
                        class=""
                        :class="docTypes !== 1 ? 'pl-md-2 pr-md-9 px-9' : 'px-9'"
                      >
                        <v-text-field v-model="filter.docMaterial" hide-details label="Malzeme"></v-text-field>
                      </v-col>

                      <!--
                      <v-col v-if="userData.role === 'PLSYR'" cols="12" :md="12" class="px-9">
                        <v-autocomplete
                          v-model="filter.docCustomer"
                          label="Müşteri"
                          :items="customerItems"
                          clearable
                          hide-details
                          item-text="name1"
                          item-value="customer"
                        ></v-autocomplete>
                        <pre></pre>
                        <v-row class="pt-6">
                      <v-col v-if="userData.role === 'PLSYR'" cols="12" class="px-9">

                      </v-col>
                    </v-row>
                    <v-divider v-if="!$vuetify.breakpoint.smAndDown && userData.role === 'PLSYR'"></v-divider> 
                      </v-col>
                      -->
                    </v-row>
                  </v-card-text>

                  <v-card-actions class="pb-9 pt-9">
                    <v-spacer />
                    <v-btn
                      color="secondary"
                      class="text-capitalize text-body-1"
                      rounded
                      x-large
                      @click="fetchDocuments()"
                    >
                      Ara
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-expand-transition>
            </v-card>
          </v-expand-transition>
          <v-card
            v-if="!status"
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar :color="isDark ? '#312d4b' : '#fafafa'" :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
              <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiFileDocument }}
                </v-icon>
                {{ title }}
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <download-excel
                v-if="!$vuetify.breakpoint.smAndDown"
                :data="json_data"
                :fields="json_fields"
                name="SATIS_BELGELERI__TUKAS.xls"
              >
                <v-btn plain text small>
                  <v-icon left>
                    {{ icons.mdiDownload }}
                  </v-icon>
                  İndir
                </v-btn>
              </download-excel>

              <v-btn plain text small @click.stop="isFilterUserSidebarActive = !isFilterUserSidebarActive">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
                Filitre
              </v-btn>
            </v-toolbar>

            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="tableItems"
              :options.sync="options"
              :items-per-page="-1"
              fixed-header
              :height="dataTableHeight"
              :loading="loading"
            >
              <template #[`item.STATX`]="{ item }">
                <v-chip :color="STATXcolor(item.STATX)" label small>
                  {{ item.STATX }}
                </v-chip>
              </template>
              <template #[`item.DOCNUM`]="{ item }">
                <v-chip color="chip" label small>
                  {{ item.DOCNUM }}
                </v-chip>
              </template>
              <template #[`item.DOCSTATE`]="{ item }">
                <v-chip :color="item.DOCSTATECOLOR" label small>
                  {{ item.DOCSTATE }}
                </v-chip>
              </template>
              <template #[`item.VALIDFROM`]="{ item }">
                <div class="text-caption font-weight-medium ls-0">
                  {{ item.VALIDFROM.slice(0, 10) }}
                </div>
              </template>
              <template #[`item.SUBTOTAL`]="{ item }">
                <div class="text-caption font-weight-medium">
                  {{ item.SUBTOTAL | currency }}
                </div>
              </template>
              <template #[`item.GROSS`]="{ item }">
                <div class="secondary--text text-caption font-weight-medium">
                  {{ item.GROSS | currency }}
                </div>
              </template>
              <template #[`item.TOTDISCAMNT`]="{ item }">
                <div class="error--text text-caption font-weight-medium">
                  {{ item.TOTDISCAMNT | currency }}
                </div>
              </template>
              <template #[`item.GRANDTOTAL`]="{ item }">
                <div class="success--text text-caption font-weight-medium">
                  {{ item.GRANDTOTAL | currency }}
                </div>
              </template>
              <template #[`item.actions`]="{ item }">
                <div class="mb-3">
                  <v-btn
                    v-if="item.B2BSIPNO"
                    class="mr-2"
                    depressed
                    x-small
                    outlined
                    :href="'./../order-tracking/view/' + item.B2BSIPNO"
                    target="_blank"
                  >
                    <v-icon small class="mr-2">
                      {{ icons.mdiEye }}
                    </v-icon>
                    <span class="mr-1">Ön İzleme</span>
                  </v-btn>
                  <v-btn depressed x-small outlined @click="detail(item.DOCNUM, item.DOCTYPE)">
                    <v-icon small class="mr-2">
                      {{ icons.mdiEye }}
                    </v-icon>
                    <span class="mr-1">Ayrıntılar</span>
                  </v-btn>

                  <v-btn
                    v-if="item.INVDETACCESS"
                    depressed
                    color="nightDark"
                    x-small
                    class="ml-3"
                    @click="download(item.DOCNUM, item.DOCTYPE)"
                  >
                    <v-icon small class="mr-2">
                      {{ icons.mdiDownload }}
                    </v-icon>
                    <span class="mr-1">Belgeyi İndir</span>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- <div v-else>
      <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
      <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
        Lütfen yukarıdan bir müşteri seçin
      </v-alert>
    </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { mdiClose, mdiDownload, mdiEye, mdiFileDocument, mdiFilter, mdiSend } from '@mdi/js'
import { computed, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import documentsStoreModule from './documentsStoreModule'
export default {
  components: {
    CustomerList,
  },
  setup() {
    onMounted(() => {
      window.addEventListener('keydown', keyDownHandler)
    })
    onUnmounted(() => {
      window.removeEventListener('keydown', keyDownHandler)
    })
    const keyDownHandler = event => {
      if (event.code === 'Escape') {
        emit('update:is-product-filter-sidebar-active', false)
      }
    }

    const DOCUMENTS_APP_STORE_MODULE_NAME = 'app-documents'
    const { isDark } = useAppConfig()
    const docTypesItems = ref([])
    const docTypes = ref(5)
    const isFilter = ref(0)
    const data1 = ref('')
    const filteringStatus = ref(false)
    const docStatusesItems = ref([])
    const { route } = useRouter()
    const { router } = useRouter()
    const statusPlasiyer = ref(false)
    onMounted(() => {
      if (store.state.secondState == true && store.state.secondMsg) {
        Vue.swal({
          title: 'Hata',
          html: store.state.secondMsg,
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.push({ name: 'payment' })
      }
      //console.log('gelen data = ' + store.state.plasiyerCustomer)
      data1.value = store.state.plasiyerCustomer
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        statusPlasiyer.value = false
        data1.value = store.state.plasiyerCustomer
        //console.log('data1 = ' + data1.value)
      } else {
        statusPlasiyer.value = true
      }
      //console.log('x = ' + data1.value)
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          statusPlasiyer.value = true
          data1.value = store.state.plasiyerCustomer
        }
      },
    )

    // Register module
    if (!store.hasModule(DOCUMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DOCUMENTS_APP_STORE_MODULE_NAME, documentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENTS_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      const param = {
        method: 'getDocTypes',
      }
      store
        .dispatch('postMethod', param)
        .then(response => {
          const data = []
          // eslint-disable-next-line no-restricted-syntax
          for (const value of Object.entries(response.detail)) {
            const item = {
              text: value[1],
              value: Number(value[0]),
            }
            data.push(item)
          }

          docTypesItems.value = data
        })
        .catch(error => {
          console.log(error)
        })
    })
    const dialog = ref(false)
    const dialogPersistent = ref(true)
    let iframe
    watch(dialog, val => {
      if (val) {
        iframe = document.createElement('iframe')
      } else {
        iframe.remove()
      }
    })
    const dialogNum = ref('')
    const download = (docnum, doctype) => {
      dialog.value = true
      dialogPersistent.value = true
      dialogNum.value = docnum
      const param = {
        method: 'getInvContent',
        doctype,
        docnum,
      }
      if (userData.role === 'PLSYR') {
        param.customer = store.state.plasiyerCustomer
      }

      store
        .dispatch('postMethod', param)
        .then(response => {
          if (response.error === 0) {
            iframe.setAttribute('id', 'print-iframe')
            iframe.width = '100%'
            iframe.height = '700'
            iframe.srcdoc = ''
            iframe.srcdoc = response.content

            document.getElementById('download').appendChild(iframe)
            document.getElementById('print-iframe').contentWindow.print()
            dialogPersistent.value = false
          } else {
            dialog.value = false
          }
        })
        .catch(error => {
          dialog.value = false
          console.log(error)
        })
    }

    const dialogDetail = ref(false)
    const detailHeaders = ref([
      {
        text: 'No',
        value: 'ITEMNUM',
        width: '60',
        sortable: false,
      },
      {
        text: 'Malzeme',
        value: 'MATERIAL',
        width: '150',
        sortable: false,
      },
      {
        text: 'Ürün',
        value: 'MTEXT',
        sortable: false,
      },
      {
        text: 'Miktar',
        value: 'QUANTITY',
        width: '120',
        sortable: false,
        align: 'right',
      },
      {
        text: 'Açık Miktar',
        value: 'AVAILQTY',
        width: '120',
        sortable: false,
        align: 'right',
      },      

      {
        text: 'Birim Fiyat',
        value: 'SPRICE',
        width: '150',
        sortable: false,
        align: 'right',
      },
      {
        text: 'Fiyat',
        value: 'SUBTOTAL',
        width: '150',
        sortable: false,
        align: 'right',
      },

      {
        text: 'KDV',
        value: 'VATAMNT',
        width: '150',
        sortable: false,
        align: 'right',
      },
      {
        text: 'Genel Toplam',
        value: 'GRANDTOTAL',
        width: '150',
        sortable: false,
        align: 'right',
      },
    ])
    const detailItems = ref([])
    const detail = (docnum, doctype) => {
      dialogDetail.value = true
      dialogPersistent.value = true
      dialogNum.value = docnum
      const param = {
        method: 'getCaniasOrderDetail',
        doctype,
        docnum,
      }
      if (userData.role === 'PLSYR') {
        param.customer = store.state.plasiyerCustomer
      }

      store
        .dispatch('postMethod', param)
        .then(response => {
          if (response.error === 0) {
            dialogPersistent.value = false
            detailItems.value = response.detail
          } else {
            dialogDetail.value = false
          }
        })
        .catch(error => {
          dialogDetail.value = false
          console.log(error)
        })
    }

    onMounted(() => {
      const param = {
        method: 'getDocStatuses',
      }
      store
        .dispatch('postMethod', param)
        .then(response => {
          const data = []
          // eslint-disable-next-line no-restricted-syntax
          for (const value of Object.entries(response.detail)) {
            const item = {
              text: value[1],
              value: Number(value[0]),
            }
            data.push(item)
          }
          data.unshift({ text: 'Hepsi', value: '' })
          docStatusesItems.value = data
        })
        .catch(error => {
          console.log(error)
        })
    })
    const isFilterUserSidebarActive = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const msg = ref('Formu doldurduktan sonra ara butonuna basın')
    const status = ref(true)

    const filter = ref({
      startDate: new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 60 * 60 * 100).toISOString().substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      docStatuses: '',
      docCustomer: '',
      docMaterial: '',
    })

    const maxDate = new Date().toISOString().slice(0, 10)

    const startMenu = ref(false)
    const endMenu = ref(false)
    const tableItems = ref([])
    const tableFooter = ref([])
    const customer = ref(null)
    const customerSearch = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)
    const tableColumns = ref([
      {
        text: 'Doküman No',
        value: 'DOCNUM',
        sortable: false,
        width: '150px',
      },
      {
        text: 'Durum',
        value: 'DOCSTATE',
        sortable: false,
        width: '150px',
      },
      {
        text: 'Müşteri',
        value: 'NAME1',
        sortable: false,
        align: 'start min-width-400',
      },

      {
        text: 'Tarih',
        value: 'VALIDFROM',
        sortable: false,
        width: '150px',
      },

      // {
      //   text: 'Para',
      //   value: 'CURRENCY',
      //   sortable: false,
      //   align: 'right',
      //   width: '70px',
      // },
      {
        text: 'Net Toplam',
        value: 'SUBTOTAL',
        sortable: false,
        align: 'right',
        width: '150px',
      },
      {
        text: 'Brüt Toplam',
        value: 'GROSS',
        sortable: false,
        align: 'right',
        width: '150px',
      },
      {
        text: 'Toplam İndirim',
        value: 'TOTDISCAMNT',
        sortable: false,
        align: 'right',
        width: '150px',
      },
      {
        text: 'Genel Toplam',
        value: 'GRANDTOTAL',
        sortable: false,
        align: 'right',
        width: '150px',
      },

      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: '300px',
      },
    ])

    const totalDocumentsListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const selectedRows = ref([])
    const json_data = ref([])

    const fetchDocuments = () => {
      const [day, month, year] = computedDateFormatted.value.split('/')
      const newEndDate = `${year}-${month}-${day}`

      const [day1, month1, year1] = computedDateFormatted1.value.split('/')
      const newStartDate = `${year1}-${month1}-${day1}`

      isFilterUserSidebarActive.value = false
      tableItems.value = []
      tableFooter.value = []
      msg.value = 'Arama yapılıyor lütfen bekleyin'
      loading.value = true
      const fetchDocumentsData = {
        method: 'getCaniasOrders',
        'search[startDate]': newStartDate,
        'search[endDate]': newEndDate,
        'search[orderType]': docTypes.value,
        'search[orderStatus]': filter.value.docStatuses,
        'search[material]': filter.value.docMaterial,
        'search[customer]': data1.value === null ? '' : data1.value,
      }

      store
        .dispatch('app-documents/fetchDocuments', fetchDocumentsData)
        .then(response => {
          if (response.detail) {
            tableItems.value = response.detail
            json_data.value = response.detail

            totalDocumentsListTable.value = Number(response.detail.length)
            loading.value = false
            status.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Veri Yok',

              width: '350px',
              icon: 'warning',
              background: '#FFB400',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#987c3d',
            })
            status.value = true
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          status.value = true
        })
    }
    watch(dialogDetail, val => {
      if (!val) {
        dialogPersistent.value = true
        detailItems.value = []
      }
    })

    const title = ref('')
    const titleFn = () => {
      if (route.value.params.folder === 'order') {
        title.value = 'Sipariş Belgeleri'
        docTypes.value = 1
      } else if (route.value.params.folder === 'waybill') {
        title.value = 'İrsaliye Belgeleri'
        docTypes.value = 3
      } else if (route.value.params.folder === 'bill') {
        title.value = 'Fatura Belgeleri'
        docTypes.value = 4
      }
    }

    function isStatx(array) {
      return array.value === 'STATX'
    }

    onMounted(() => {
      if (route.value.params.folder !== 'order') {
        if (!tableColumns.value.find(isStatx)) {
          tableColumns.value.unshift({
            text: 'Statü',
            value: 'STATX',
            sortable: false,
            width: '90px',
          })
        }
      } else if (tableColumns.value.find(isStatx)) {
        tableColumns.value.shift()
      }
    })

    watch(
      () => route.value.params.folder,
      () => {
        if (route.value.params.folder !== 'order') {
          if (!tableColumns.value.find(isStatx)) {
            tableColumns.value.unshift({
              text: 'Statü',
              value: 'STATX',
              sortable: false,
              width: '90px',
            })
          }
        } else if (tableColumns.value.find(isStatx)) {
          tableColumns.value.shift()
        }
        titleFn()
        status.value = true
      },
    )
    onMounted(() => {
      titleFn()
    })

    const STATXcolor = val => {
      let result
      if (val === 'Açık') {
        result = 'error'
      } else if (val === 'Kapalı') {
        result = 'success'
      } else {
        result = 'warning'
      }

      return result
    }
    const filterClear = () => {
      isFilterUserSidebarActive.value = false
      filter.value = {
        id: '',
        aktif: '',
        baslik: '',
        urun_kodu: '',
        plant: '',
      }
      Vue.swal({
        title: 'Temizlendi',
        text: 'Filitre Temizlendi',
        icon: 'success',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }
    onMounted(() => {
      store
        .dispatch('app/fetchCustomersBySalDept')
        .then(response => {
          customerItems.value = response.detail
        })
        .catch(error => {
          console.log(error)
        })
    })

    const date = ref(new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10))
    const date1 = ref(new Date(new Date().setDate(new Date().getDate() - 45)).toISOString().substr(0, 10))
    const menu1 = ref(false)
    const menu2 = ref(false)

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    }

    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const parseDate = dates => {
      if (!dates) return null
      const [month, day, year] = dates.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value))
    const computedDateFormatted1 = computed(() => formatDate(date1.value))

    watch(date, () => {
      dateFormatted = formatDate(date.value)
    })
    return {
      dialogDetail,
      menu1,
      menu2,
      date,
      date1,
      dateFormatted,
      computedDateFormatted,
      computedDateFormatted1,
      parseDate,
      formatDate,
      detail,
      detailItems,
      detailHeaders,
      isFilter,
      filterClear,
      statusPlasiyer,
      title,
      customerItems,
      customerSearch,
      customerLoading,
      customer,
      isDark,
      tableColumns,
      tableItems,
      json_data,
      tableFooter,
      loading,
      selectedRows,
      options,
      data1,
      dialogNum,
      startMenu,
      endMenu,
      msg,
      status,
      userData,
      dialog,
      totalDocumentsListTable,
      fetchDocuments,
      isFilterUserSidebarActive,
      docTypesItems,
      docTypes,
      docStatusesItems,
      filter,
      download,
      dialogPersistent,
      STATXcolor,

      // icons
      icons: {
        mdiDownload,
        mdiFilter,
        mdiSend,
        mdiEye,
        mdiFileDocument,
        mdiClose,
      },
    }
  },

  data() {
    return {
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? (this.userData.role === 'PLSYR' ? 140 : 48) : 100,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        Tip: 'DOCTYPEX',
        'Doküman No': 'DOCNUM',

        Statü: 'STATX',
        Tarih: 'VALIDFROM',
        Para: 'CURRENCY',
        'Net Toplam': 'SUBTOTAL',
        'Brüt Toplam': 'GROSS',
        'Toplam İndirim': 'TOTDISCAMNT',
        'Genel Toplam': 'GRANDTOTAL',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style>
.v-application .white--text {
  color: 'black' !important;
}
</style>
